'use strict';

angular.module('cpformplastApp.items')
  .config(function ($stateProvider) {
    $stateProvider
      .state('mechanic-planning', {
        url: '/mechanic-planning',
        templateUrl: 'app/mechanic-planning/mechanic-planning.html',
        controller: 'mechanicPlanningController',
        authenticate: ['admin','management_dept','salesmen_dept','receptionist']
      });
  });
